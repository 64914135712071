import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import Shows from "../data/shows"

import "animate.css"

const ChatParagraph = ({ children, className }) => (
  <p
    className={`animated fadeInUp faster ${className}`}
    style={{
      background: `#eee`,
      borderRadius: 8,
      padding: `.3em .5em`,
    }}
  >
    {children}
  </p>
)

const CallToAction = ({ children, className }) => (
  <p
    className={`animated fadeInUp faster ${className}`}
    style={{ textAlign: "right" }}
  >
    {children}
  </p>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Beep, boop.</h1>
    <ChatParagraph className="delay-1s">
      Hey there Human, I bet you're wondering when you can see me perform improv
      comedy with my six Human friends.
    </ChatParagraph>
    <ChatParagraph className="delay-3s">
      Fear not, the Humans scheduled my next show for{" "}
      <b>Friday, January 31st</b>.
    </ChatParagraph>
    <ChatParagraph className="delay-5s">
      <span>Would you like to buy tickets, Human?</span>
    </ChatParagraph>
    <CallToAction className="delay-5s">
      <button
        style={{
          border: 0,
          borderRadius: 4,
          padding: `.3em 1em`,
          fontWeight: "bold",
          color: "white",
          textTransform: "uppercase",
          background: "#F63DDA",
        }}
      >
        Buy Tickets
      </button>
    </CallToAction>
  </Layout>
)

export default IndexPage
